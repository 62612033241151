(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/banner-helpers/assets/javascripts/images.js') >= 0) return;  svs.modules.push('/components/banner_common/banner-helpers/assets/javascripts/images.js');

'use strict';

var _svs$content;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
const trinidad = isServer && require('trinidad-core').core;
const logger = isServer ? trinidad.logger('banner_common: banner-helpers') : svs.core.log.getLogger('banner_common: banner-helpers');
const getLogotype = isServer ? trinidad.components.require('content', 'logotype-provider').getLogotype : (_svs$content = svs.content) === null || _svs$content === void 0 || (_svs$content = _svs$content.logotypeProvider) === null || _svs$content === void 0 ? void 0 : _svs$content.getLogotype;
const getMediaQueries = (mediaQueries, key) => {
  if (mediaQueries) {
    return mediaQueries[key];
  }
};
const getUrls = (imageRelation, cdn2, prePreviewImage) => {
  const previewImage = _objectSpread({}, prePreviewImage);
  const availableWidths = (imageRelation === null || imageRelation === void 0 ? void 0 : imageRelation.availableWidths) || previewImage.availableWidths;
  const urlEntries = availableWidths.map(width => {
    const key = "w".concat(width);
    const protocol = 'https:';
    const params = "?w=".concat(width);
    const url = "".concat(protocol).concat(cdn2).concat(imageRelation.url.replace('{width}', width)).concat(params);
    return [key, url];
  });
  return Object.fromEntries(urlEntries);
};
const getLogotypeObject = async layoutConfig => {
  const logotype = {};
  const {
    logotypeName
  } = layoutConfig || {};
  let image = {};
  let data;
  let urls;
  if (logotypeName) {
    if (trinidad) {
      const {
        cdn2,
        cms: {
          logotypes
        }
      } = trinidad.config.options;
      if (logotypes) {
        data = logotypes.find(_ref => {
          let {
            name
          } = _ref;
          return name.toLowerCase().replace(/\s+/g, '-') === logotypeName;
        });
        urls = getUrls(data.image, cdn2);
        image = _objectSpread(_objectSpread({}, data.image), {}, {
          urls
        });
      }
    }
    Object.assign(logotype, {
      data,
      image
    });
  }
  return {
    logotype
  };
};
const formatImagesV2 = async (model, key) => {
  const {
    images: modelImages,
    layoutConfig
  } = model || {};
  if (!(modelImages !== null && modelImages !== void 0 && modelImages.length)) {
    logger.warn('The image array is empty.');
    return;
  }
  const imageObjectReducer = (acc, curr) => {
    var _layoutConfig$mediaQu;
    const item = curr;
    const {
      altText,
      height,
      title,
      width
    } = item;
    const itemKey = item[key];
    const aspectRatio = {
      width,
      height
    };
    const dominantColor = '#ccc';
    const mediaQuery = layoutConfig === null || layoutConfig === void 0 || (_layoutConfig$mediaQu = layoutConfig.mediaQueries) === null || _layoutConfig$mediaQu === void 0 ? void 0 : _layoutConfig$mediaQu[0];
    const mediaQueries = getMediaQueries(mediaQuery, itemKey);
    const image = {
      [itemKey]: _objectSpread(_objectSpread(_objectSpread({}, item), mediaQueries), {}, {
        altText,
        aspectRatio,
        dominantColor,
        title
      })
    };
    return _objectSpread(_objectSpread({}, acc), image);
  };

  const images = modelImages.reduce(imageObjectReducer, {});

  if (images.mobileImage_BANNER_MOBILE || images.mobileImage) {
    if (images.mobileImage && !images.mobileImage_BANNER_MOBILE) {
      images.mobileImage_BANNER_MOBILE = images.mobileImage;
    }
    Object.assign(images, {
      productImage_BANNER_MOBILE: images.mobileImage_BANNER_MOBILE
    });
    delete images.mobileImage_BANNER_MOBILE;
  }

  const logotypeObject = await getLogotypeObject(layoutConfig);
  Object.assign(images, logotypeObject);
  return images;
};

const formatImagesV1 = async (model, key) => {
  var _model$layoutConfig;
  const initialValue = {};
  const imagesArray = model === null || model === void 0 ? void 0 : model.images;
  const mediaQueries = model === null || model === void 0 || (_model$layoutConfig = model.layoutConfig) === null || _model$layoutConfig === void 0 ? void 0 : _model$layoutConfig.mediaQueries;
  if (imagesArray && imagesArray.length) {
    var _model$layoutConfig2;
    const images = imagesArray === null || imagesArray === void 0 ? void 0 : imagesArray.reduce((image, item) => _objectSpread(_objectSpread({}, image), {}, {
      [item[key]]: _objectSpread(_objectSpread(_objectSpread({}, item), {}, {
        dominantColor: '#ccc',
        aspectRatio: {
          width: item.width,
          height: item.height
        }
      }, getMediaQueries(mediaQueries === null || mediaQueries === void 0 ? void 0 : mediaQueries[0], item[key])), {}, {
        altText: item === null || item === void 0 ? void 0 : item.altText,
        title: item === null || item === void 0 ? void 0 : item.title
      })
    }), initialValue);
    if (images.mobileImage_BANNER_MOBILE || images.mobileImage) {
      if (images.mobileImage && !images.mobileImage_BANNER_MOBILE) {
        images.mobileImage_BANNER_MOBILE = images.mobileImage;
      }
      delete Object.assign(images, {
        productImage_BANNER_MOBILE: images.mobileImage_BANNER_MOBILE
      }).mobileImage_BANNER_MOBILE;
    }
    if ((_model$layoutConfig2 = model.layoutConfig) !== null && _model$layoutConfig2 !== void 0 && _model$layoutConfig2.logotypeName) {
      var _model$layoutConfig3;
      images.logotype = getLogotype((_model$layoutConfig3 = model.layoutConfig) === null || _model$layoutConfig3 === void 0 ? void 0 : _model$layoutConfig3.logotypeName);
    }
    return images;
  }
  logger.warn('The imagearray is empty');
};

const formatImages = async (model, key) => {
  var _model$layoutConfig4;
  return ((_model$layoutConfig4 = model.layoutConfig) === null || _model$layoutConfig4 === void 0 ? void 0 : _model$layoutConfig4.version) === '2' ? await formatImagesV2(model, key) : await formatImagesV1(model, key);
};
if (isServer) {
  module.exports = {
    formatImages
  };
} else {
  svs.banner_common.banner_helpers.formatImages = formatImages;
}

 })(window);